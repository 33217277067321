body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	background: $colour-white;
	position: relative;
	color: $colour-indigo;
	overflow-x: hidden;

	* {
	  box-sizing: inherit;
	  -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;

	  &:after,
	  &:before {
		padding: 0;
		margin: 0;
		box-sizing: inherit;
	  }
	}

	&.exe-business {
		background: $colour-lightgrey;
	}
}

img {
	max-width: 100%;
}

.btn {
	display: inline-block;
	border-radius: 10px;
	padding: 15px 25px;
	background: $colour-lime;
	border: 2px solid $colour-lime;
	color: $colour-indigo;
	transition: all 0.1s;
	font-family: $font-heading;

	&:hover {
		transform: scale(1.05) !important;
		color: $colour-indigo;
	}
}

.btn.btn-sm {
	padding: 0.3571429rem 0.64285714rem;

	.si {
		font-weight: bold;
	}
}

.btn-dual-secondary:not([disabled]):not(.disabled):active {
    color: $colour-indigo;
    background-color: $colour-lime;
}

.btn-alt-warning {
	display: inline-block;
	border-radius: 10px;
	padding: 15px 25px;
	background: $colour-cream;
	border: 2px solid $colour-cream;
	color: $colour-indigo;
	transition: all 0.1s;
	font-family: $font-heading;

	&:hover {
		transform: scale(1.05) !important;
		color: $colour-indigo;
	}
}

.page-item.active {
	.page-link {
		z-index: 1;
		color: $colour-white;
		background-color: $colour-indigo;
		border-color: $colour-indigo;
	}
}

.border-radius-0 {
	border-radius: 0 !important;
}

.bg-texture {
	background-image: url('/media/bg-texture.svg') !important;
    background-position: right top !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-color: $colour-indigo !important;
}

.ajax-loader {
	position: absolute;
	background-color: $colour-white;
	z-index: 1020;
	left:0;
	top:0;
}

.ajax-widget-loader {
	min-height: 230px;
}

.ajax-widget-loader.h60 {
	min-height: 60px;
}

.font-size-26 {
	font-size: 26px;
}

.block {
	border: 2px solid $colour-spearmint;
	box-shadow: none;
}

.account-details-header {
	margin: 0 auto;
    padding: 20px 20px 1px;
    width: 100%;
    overflow-x: visible;
	background: $colour-spearmint;
	cursor: pointer;
}

#account-details-content {
	display: none;
}

.nav-tabs-block {
	background-color: $colour-spearmint;

	.nav-link {
		color: $colour-indigo;
		padding-top: 20px;
		padding-bottom: 20px;

		&:hover {
			color: $colour-indigo;
		}

		&:active {
			color: $colour-indigo;
		}

		&:focus {
			color: $colour-indigo;
		}
	}
}

.tab-pane {
	.block-header {
		a {
			color: $colour-indigo;

			&:after {
				font-family: 'FontAwesome';
				content: "\f068";
				right: 20px;
				position: absolute;
			}
			&.collapsed:after {
				content: "\f067";
			}
		}
	}
}

table {
	&.dataTable {
		&.dtr-inline {
			&.collapsed > tbody > tr {
				&.parent >td:first-child:before {
					background-color: $colour-red !important;
				}

				&> td:first-child {
					padding-left: 40px !important;

					&:before {
						height: 16px !important;
						width: 16px !important;
						top: inherit !important;
						left: 10px !important;
						padding: 1px !important;
						line-height: 16px !important;
						background-color: $colour-darklime !important;
					}
				}
			}

			&.collapsed > tbody > tr {
				&.parent>th:first-child:before {
					background-color: $colour-red !important;
				}

				&> th:first-child {
					padding-left: 40px !important;

					&:before {
						height: 16px !important;
						width: 16px !important;
						top: inherit !important;
						left: 10px !important;
						padding: 1px !important;
						line-height: 16px !important;
						background-color: $colour-darklime !important;
					}
				}
			}
		}
	}
}

.modal {
	padding-right: 0px !important;
}

.badge-success {
    background-color: $colour-darklime;
}

.badge-danger {
    background-color: $colour-red;
}

.badge-info {
    background-color: $colour-blue;
}

.transform-0 {
	transform: rotate(0deg);
}

.transform-180 {
	transform: rotate(180deg);
}

[data-notify="container"] {
	&.alert {
		box-shadow: 0 6px 43px -8px rgba(0, 0, 0, 0.35);
    	border-left: 5px solid;
	}
}

.validation-feedback {
    font-family: $font-subheading;
}

.pulse-red {
	box-shadow: 0 0 0 rgba(231, 76, 60, 0.4);
  	animation: pulse-red 2s infinite;
}

table.dataTable {
    border-collapse: separate !important;
}

.dataTables_wrapper {
	position: relative;
	clear: both;
}

div.dataTables_wrapper div.dataTables_processing {
	width: 100%;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	margin-left: inherit;
	margin-top: inherit;
	background: #fff;
	z-index: 9999;
	padding: inherit;
    margin-top: -1px;
}

.otp-form-wrapper {
	width: 100%;
	height: 100%;
	z-index: 9999;
	left: 0;
}

.otp-form {
	z-index: 1;
}

.bg-indigo-pulse {
	background: $colour-indigo;
	background: linear-gradient(135deg, $colour-indigo 0%, $colour-lime 100%) !important;
}

@-webkit-keyframes pulse-red {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(231, 76, 60, 0.4);
	}
	70% {
		-webkit-box-shadow: 0 0 0 10px rgba(231, 76, 60, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(231, 76, 60, 0);
	}
}
@keyframes pulse-red {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(231, 76, 60, 0.4);
		box-shadow: 0 0 0 0 rgba(231, 76, 60, 0.4);
	}
	70% {
		-moz-box-shadow: 0 0 0 10px rgba(231, 76, 60, 0);
		box-shadow: 0 0 0 10px rgba(231, 76, 60, 0);
	}
	100% {
		-moz-box-shadow: 0 0 0 0 rgba(231, 76, 60, 0);
		box-shadow: 0 0 0 0 rgba(231, 76, 60, 0);
	}
}

.pulse-yellow {
	box-shadow: 0 0 0 rgba(208, 111, 48, 0.4);
  	animation: pulse-red 2s infinite;
}

@-webkit-keyframes pulse-yellow {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(208, 111, 48, 0.4);
	}
	70% {
		-webkit-box-shadow: 0 0 0 10px rgba(208, 111, 48, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(208, 111, 48, 0);
	}
}
@keyframes pulse-yellow {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(208, 111, 48, 0.4);
		box-shadow: 0 0 0 0 rgba(208, 111, 48, 0.4);
	}
	70% {
		-moz-box-shadow: 0 0 0 10px rgba(208, 111, 48, 0);
		box-shadow: 0 0 0 10px rgba(208, 111, 48, 0);
	}
	100% {
		-moz-box-shadow: 0 0 0 0 rgba(208, 111, 48, 0);
		box-shadow: 0 0 0 0 rgba(208, 111, 48, 0);
	}
}

.item-circle-sm {
	width: 15px;
    height: 15px;
}

@include media-breakpoint-down(md) {
	.nav-tabs-block {
		.nav-item {
			display: block;
			width: 100%;
			text-align: center;
		}
	}
}

@include media-breakpoint-down(sm) {
	.nav-tabs-block {
		.nav-item {
			display: block;
			width: 100%;
			text-align: center;
		}
	}
}

@include media-breakpoint-down(xs) {
	.nav-tabs-block {
		.nav-item {
			display: block;
			width: 100%;
			text-align: center;
		}
	}
}

.footer {
    width: 100%;
    color: $colour-white;
	font-size: 16px;
	position: relative;

	.footer-inner {
		background: $colour-indigo;
		padding: 0 0 150px 0;

		@include media-breakpoint-down(sm) {
			text-align: center;
		}

		@include media-breakpoint-down(xs) {
			text-align: center;
		}

		.row > div {
			margin-top: 80px;
		}

		.logo a {
			display: inline-block;
			width: 80px;
		}

		.logo h2 {
			color: $colour-lightlime;
			margin-bottom: 0;
		}

		.logo p {
			margin: 0;
		}

		h5 {
			text-transform: uppercase;
			font-size: 16px;
			color: $colour-lightlime;
			margin: 0 0 30px 0;
		}

		ul {
			padding: 0;
			margin: 0;

			> li {
				font-family: $font-subheading;
				list-style-type: none;

				a {
					color: $colour-white;
					margin-bottom: 5px;
					display: inline-block;
					text-decoration: none;

					&:hover {
						color: $colour-darklime;
					}
				}

				ul {
					display: none;
				}
			}
		}

		.copyright {
			text-align: center;
			font-size: 14px;
			position: absolute;
			bottom: 30px;
			left: 15px;
			right: 15px;
		}
	}
}

@include media-breakpoint-up(lg) {
	#page-container {
		&.main-content-boxed {
			> #page-footer {
				.content {
					max-width: 1440px;
				}
			}
		}
	}
}

svg:not(:root) {
    overflow: hidden;
}

img:before {
	content: ' ';
	display: block;
	position: absolute;
	top:0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: white;
	background-image:url('../media/img-placeholder.svg');
	background-size: 100%;
	background-position: center;
	color: transparent;
}

.img-thumb {
	min-height: 100px;
	min-width: 220px;
}

.popover {
	z-index: 1025 !important;
}

.sale-reminder-header {
	position: relative;
    height: 300px;
	overflow: hidden;

	.overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		background: #000000;
		opacity: 0.5;
	}

	&:before {
		position: absolute;
		width: 100%;
		height: 100%;
		content: '';
		background: url(/media/sale-reminder-header-bg.png) 100% 0%;
		background-size: cover;
		background-position: top;
		-webkit-transform-origin: right;
		-ms-transform-origin: right;
		transform-origin: right;
	}
}

.sale-reminder-header-content {
	position: absolute;
	top: 0%;
    left: 0%;
    right: 0;
    bottom: 0;
}

.sale-reminder-header-title {
	.heading-one {
		color: #fff;
		font-size: 35px;
	}
	.heading-two {
		color: #fff;
		font-size: 25px;
	}
	.heading-three {
		color: #fff;
		font-size: 40px;
	}
}

.cc-selector input {
    margin:0;padding:0;
    -webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
}

.visa-card {
	background-image:url(/media/visa.png);
}

.master-card {
	background-image:url(/media/master.png);
}

.amex-card {
	background-image:url(/media/amex.png);
}

.cc-selector input:active +.cc {
	opacity: .5;
}
.cc-selector input:checked +.cc {
    -webkit-filter: none;
	-moz-filter: none;
	filter: none;
}

.cc {
    cursor:pointer;
    background-size:contain;
    background-repeat:no-repeat;
    display:inline-block;
	width:70px;
	height:44px;
    -webkit-transition: all 100ms ease-in;
	-moz-transition: all 100ms ease-in;
	transition: all 100ms ease-in;
    -webkit-filter: brightness(1.8) grayscale(1) opacity(.7);
	-moz-filter: brightness(1.8) grayscale(1) opacity(.7);
	filter: brightness(1.8) grayscale(1) opacity(.7);
}

.cc:hover {
    -webkit-filter: brightness(1.2) grayscale(.5) opacity(.9);
	-moz-filter: brightness(1.2) grayscale(.5) opacity(.9);
	filter: brightness(1.2) grayscale(.5) opacity(.9);
}

.fw-65 {
	width: 65px;
}

.fw-200 {
	width: 200px;
}

.invalid-feedback {
	color: $colour-red !important;
}

.flatpickr-clear {
	width: 100%;
    padding: 10px;
    background: $colour-lightgrey;
	cursor: pointer;
	color: $colour-grey;

	&:hover {
		background: $colour-midgrey;
	}
}

.bottom-details {
	margin-top: 55px;

	.title {
		font-size: 32px;
		color: #004346;

		a {
			color: #004346;
			text-decoration: none !important;
		}
	}

	.contact-item {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		width: auto;
		margin: 0 0 12px;
		padding: 0 0 12px;
		border-bottom: 2px solid #d7dddd;

		h4 {
			font-size: 18px;
		}

		h4, p {
			color: #004346;
			text-align: left;
		}

		&:last-of-type {
			border: none;
		}
	}

	.contact-item>div:first-of-type {
		width: 220px;
	}

	.contact-item>div:last-of-type {
		min-width: 450px;
	}

	.exefix-banner-small {
		background: #004346;
		padding: 15px 30px;
		border-radius: 20px;
		color: #fff;
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;

		p {
			margin: auto;
		}
	}

	@media (max-width: 992px) {
        .title {
            font-size: 24px;
            line-height: 34px;

            a {
                color: #004346;
            }
        }

        .contact-item {
            flex-direction: column;
            margin: 0 10px 15px 10px;
            padding: 0 0 15px 0;
            width: calc(100% - 40px);

            > div {
                width: auto !important;
                min-width: auto !important;

                &:first-of-type {
                    margin-bottom: 6px;
                }
            }

            p {
                white-space: normal;
                line-height: 22px;
                text-align: center;
                font-size: 14px;
            }

            h4 {
                font-size: 16px;
                text-align: center;
            }
        }

        .exefix-banner-small {
            padding: 10px 15px;
            margin: 0 15px;
        }
    }
}

.action-item-tabs {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
	margin-bottom: 25px;

	a {
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		text-align: center;
		padding: 17px 5px;
		color: #004346;
		font-family: engrezmedium,sans-serif;
		min-width: 20%;
		-webkit-transition: all .2s;
		transition: all .2s;
		-ms-flex-preferred-size: 30%;
    	flex-basis: 30%;
		background: #c1eecd;
		border: 1px solid #fff;

		&:hover {
			background: #63d481;
    		color: #fff;
		}

		@media (max-width: 576px) {
			display: block;
			color: #004346;
			font-family: engrezmedium,sans-serif;
			width: 100%;
			border-bottom: 1px solid #f2f2f2;
			margin-bottom: 1px;
			padding-bottom: 12px;
			flex-basis: 100%;
		}
	}

	@media (max-width: 576px) {
		&:last-of-type {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: none;
		}
	}
}

.child-item-tabs{
	&:not(:last-child) {
		border-bottom: 8px solid #e5ecec;
	}

	.service-row {
		margin: 0;
		padding: 20px;
		position: relative;
		border: none;

		.child-service-toggle-wrapper {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translate(-50%, -50%);
			height: 25px;
		}

		.bar-chart {
			overflow: hidden;
			background: #e5ecec;
			height: 12px;

			.progress {
				height: 15px !important;
				background: #63d481;

				.bg-red {
					background-color: $colour-red;
				}

				.bg-yellow {
					background-color: $colour-cream;
				}
			}
		}

		.sub-menu {
			display: flex;
			align-items: center;
			height: 25px;

			&:before {
				display: block;
				color: white;
				border: 2px solid white;
				border-radius: 14px;
				box-shadow: 0 0 3px #444;
				box-sizing: content-box;
				text-align: center;
				text-indent: 0 !important;
				font-family: 'Courier New', Courier, monospace;
				content: '+';
				height: 16px !important;
				width: 16px !important;
				top: inherit !important;
				left: -10px !important;
				padding: 1px !important;
				line-height: 16px !important;
				background-color: $colour-darklime !important;
				background-position: center;
				text-align: center;
			}

			&.collapsed:before {
				content: '-';
				background-color: $colour-red !important;
			}
		}

		@media (max-width: 576px){
			&.sub-service-row {
				padding-left: 30px;
				background: url(../images/icon-sub-service-arrow1.png) no-repeat center left 10px;
				background-size: 20px;
			}

			.charts{
				display: none;
			}

			.manage {
				width: auto;
				position: absolute;
				right: 0;
				top: 0;
				bottom: 0;
				margin: auto 0;
				padding: 0 10px 0 0;
			}

		}
	}
	.arrow-right {
		cursor: pointer;
	}

	.bar-chart, .bar-chart-unlimited {
		border-radius: 5px;
	}

	.truncate{
		font-size: 13px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.unlimited-bar {
		width: 100%;
		height: 10px;
		border-bottom: 4px dotted #e5ecec;
	}

	.usage-row-min-width {
		min-width: 140px;
	}

	.title-text{
		margin-left: 20px;
	}
	.icon img {
		max-height: 55px;
		min-width: 45px;
	}
}

.simplebar-offset {
	width:100%;
}

.table-advance-filters-toggle {
	margin-top: 30px;
	margin-bottom: 15px;
	min-height: 35px;

	a {
		font-weight: bold;
		display: flex;
		align-items: center;
		font-family: $font-heading;
		cursor: pointer;
		margin-bottom: 20px;
		float: right;

		img {
			width: 14px;
			height: 14px;
			margin-left: 10px;
			margin-bottom: 0px;
		}
	}

	i {
		font-size: 18px;
		margin-right: 8px;
	}
}

.underline {
	text-decoration: underline;

	&:hover {
		text-decoration: underline;
	}
}

.block-content {
	.block-inner {
		padding: 1.5rem!important;
		background-color: $colour-white;
	}
}

.service-back {
	cursor: pointer;

	img {
		transform: rotate(180deg);
	}
}

.img-avatar-thumb {
	background: #fff;
}

#iam-form {
	.ajax-loader {
		background-color: transparent;
		background: transparent;
	}
}

.iam {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;

	label {
		height: inherit;
		line-height: inherit;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		text-align: center;
		padding: 17px 5px;
		color: #004346;
		font-family: engrezmedium,sans-serif;
		min-width: 20%;
		-webkit-transition: all .2s;
		transition: all .2s;
		-ms-flex-preferred-size: 30%;
		background: #c1eecd;
		border: 1px solid #fff;
		flex-basis: 30%;

		@media (max-width: 991.98px) {
			flex-basis: 50%;
		}

		@media (max-width: 767.98px) {
			flex-basis: 100%;
		}

		img {
			margin-bottom: 15px;
			background: #fff;
		}
	}
}

.exe-business {
	.block {
		background-color: transparent;
		border: none !important;

		.block-header-default {
			background: $colour-indigo;

			.block-title {
				color: $colour-white;
			}

			&.bg-transparent {
				.block-title {
					color: $colour-indigo;
				}
			}
		}

		.table {
			&:not(.striped-rows) {
				border: none !important;

				tr {
					td {
						border: none;
						background: $colour-white;
						padding: 20px;
					}

					th {
						border: none;
					}
				}
			}

			&.table-inner {
				tr {
					td {
						border: none;
						background-color: $colour-spearmint;
						padding: 20px;
					}

					th {
						border: none;
					}
				}
			}
		}

		.table th, .table td {
			border-top: 8px solid $colour-lightgrey !important;
		}

		.block-content {
			background: $colour-white;
			margin-top: 20px;

			&.no-backgroud {
				background: transparent !important;
				padding: 0px;
			}

			&:not(.no-backgroud) {
				table {
					tbody {
						tr {
							&.odd {
								background: $colour-lightgrey;
							}

							&:not(.odd, .even) {
								&:nth-child(odd) {
									background: $colour-lightgrey;
								}
							}
						}
					}

					th, td {
						border-top: none !important;
					}
				}

				.table {
					th, td {
						border-top: none !important;
					}
				}
			}

			&.bg-lime {
				background-color: $colour-indigo !important;

				h2 {
					color: $colour-white;
				}
			}
		}

		.nav-tabs-block {
			background-color: $colour-white;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			width: 100%;
			margin-bottom: 55px;

			.nav-item {
				-webkit-box-pack: center;
				-ms-flex-pack: center;
				justify-content: center;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				-webkit-box-flex: 1;
				-ms-flex-positive: 1;
				flex-grow: 1;
				text-align: center;
				color: $colour-indigo;
				font-family: $font-subheading;
				min-width: 20%;
				-webkit-transition: all .2s;
				transition: all .2s;
				flex-basis: 30%;

				@media (max-width: 991.98px) {
					flex-basis: 50%;
				}

				@media (max-width: 767.98px) {
					flex-basis: 100%;
				}

				.nav-link {
					padding: 0.5rem 1rem;
					padding: 30px 20px;
					font-size: 110%;
					font-weight: inherit;
					line-height: 1.15 !important;

					&.active {
						background-color: $colour-lime;
						color: $colour-white;

						&:hover {
							background-color: $colour-lime
						}
					}

					&:hover {
						background-color: $colour-spearmint
					}
				}
			}
		}

		.si-arrow-up {
			content: "";
			color: transparent;
			font-size: 20px;
			background: url('/images/arrow-down.svg') no-repeat 50%;
			transform: rotate(180deg);
			display: block;
			width: 20px;
			background-size: 20px;
		}

		.si-arrow-down {
			content: "";
			color: transparent;
			font-size: 20px;
			background: url('/images/arrow-down.svg') no-repeat 50%;
			transform: rotate(0deg);
			display: block;
			width: 20px;
			background-size: 20px;
		}

		.tab-pane .block-header a.collapsed:after {
			content: "";
			color: transparent;
			font-size: 20px;
			background: url('/images/arrow-down.svg') no-repeat 50%;
			transform: rotate(0deg);
			display: block;
			width: 20px;
			background-size: 20px;
			height: 20px;
			top: 26px;
		}

		.tab-pane .block-header a:after {
			content: "";
			color: transparent;
			font-size: 20px;
			background: url('/images/arrow-down.svg') no-repeat 50%;
			transform: rotate(180deg);
			display: block;
			width: 20px;
			background-size: 20px;
			height: 20px;
			top: 26px;
		}

		.form-general {
			.form-control {
				border: 1px solid $colour-grey;
				border-radius: 5px;
			}

			label {
				color: $colour-grey;
				font-size: 16px !important;
				font-weight: inherit !important;
			}

			.select2-container--default {
				.select2-selection--multiple {
					border: 1px solid $colour-grey;
					border-radius: 5px;

					.select2-selection__choice {
						background-color: $colour-indigo;
					}
				}

				.select2-selection--single {
					border: 1px solid $colour-grey;
					border-radius: 5px;

					.select2-selection__arrow {
						width: 30px;

						b {
							border: none;
							background: url('/images/arrow-down.svg') no-repeat 50%;
							transform: rotate(0deg);
							height: 20px;
							left: 50%;
							margin-left: -10px;
							margin-top: -2px;
							position: absolute;
							top: 34%;
							width: 20px;
						}
					}
				}

				&.select2-container--open {
					.select2-selection--single {
						.select2-selection__arrow {
							width: 30px;

							b {
								border: none;
								background: url('/images/arrow-down.svg') no-repeat 50%;
								transform: rotate(180deg);
								height: 20px;
								left: 50%;
								margin-left: -10px;
								margin-top: -2px;
								position: absolute;
								top: 34%;
								width: 20px;
							}
						}
					}
				}

				&.select2-container--focus {
					.select2-selection--multiple {
						border: 1px solid $colour-grey;
					}

					.select2-selection--single {
						border: 1px solid $colour-grey;
					}
				}

				.select2-search--inline {
					.select2-search__field {
						font-family: $font-regular;
					}
				}
			}

			.btn {
				display: inline-block;
				border-radius: 10px !important;
				padding: 15px 25px !important;
				background: $colour-lime;
				border: 2px solid $colour-lime;
				color: $colour-indigo !important;
				transition: scale 0.1s;
				font-family: $font-heading;
				height: inherit !important;

				&:hover {
					transform: scale(1.05) !important;
					color: $colour-indigo;
				}

				&.disabled {
					opacity: 0.6;
				}

				&.color-red {
					background: $colour-error-bg;
					color: $colour-error-text;
					border-color: $colour-error-border;
				}

				&.btn-small {
					padding: 10px 25px;
				}

				&.is-active {
					background: $colour-indigo;
					border-color: $colour-indigo;
					color: $colour-white;
				}

				&.pt-12 {
					padding-top: 12px !important;
				}

				&.pb-12 {
					padding-bottom: 12px !important;
				}
			}
		}

		&.block-themed {
			border: 1px solid $colour-indigo !important;

			> .block-header {
				background: none !important;
				background-color: $colour-indigo !important;
			}
		}

		.block-inner {
			.block-header {
				background-color: transparent !important;
				padding: 17px 20px 17px 0;
				border-bottom: 2px solid $colour-spearmint;
				padding-left: 20px;
				padding-right: 20px;

				.block-title {
					color: $colour-indigo !important;
				}
			}

			.block-content {
				margin-top: 0px;
			}
		}
	}

	.modal {
		.block {
			.form-general {
				.btn {
					padding: 12px 25px !important;

					&.pt-12 {
						padding-top: 12px !important;
					}

					&.pb-12 {
						padding-bottom: 12px !important;
					}
				}
			}

			.block-header {
				&.bg-transparent {
					.block-title {
						border-bottom: 3px solid $colour-lime;
						padding-bottom: 15px;
					}
				}

				.block-options {
					.si-close {
						content: "";
						color: transparent;
						font-size: 20px;
						background: url('/images/icon-close-white.svg') no-repeat 50%;
						transform: rotate(0deg);
						display: block;
						width: 55px;
						background-size: 55px;
					}
				}
			}

			.slick-dotted-inner {
				table {
					width: 100%;

					thead {
						font-family: $font-heading;
					}

					tbody {
						&.odd {
							background: $colour-lightgrey;
						}

						&:not(.odd, .even) {
							&:nth-child(odd) {
								background: $colour-lightgrey;
							}
						}
					}

					td {
						padding: 0.8em 1.2em;
					}
				}
			}
		}
	}

	#account-details-content {
		.block {
			.block-content {
				margin-top: 0px;
			}
		}
	}

	.block-header {
		padding: 25px 30px;
		position: relative;

		&:not(.bg-transparent) {
			background-color: $colour-indigo !important;
		}

		&.bg-lightlime {
			background-color: $colour-indigo !important;

			.block-title {
				color: $colour-white;
			}

			.si-close {
				color: $colour-lime;
			}
		}

		h2 {
			margin: 0px;
			color: $colour-white;
		}
	}

	.dashboard {
		.block {
			.block-content {
				margin-top: 0px;
			}
		}

		.service-stats {
			.block-content{
				&.block-content-full {
					padding-top: 0px;
					padding-left: 0px;
					padding-right: 0px;
					background: transparent;

					.block {
						background: $colour-white;

						.block-header {
							padding-top: 35px;

							.block-title {
								border-bottom: 3px solid $colour-indigo;
							}
						}
					}
				}
			}
		}
	}

	.account-details-header {
		background-color: $colour-indigo !important;

		h2 {
			color: $colour-white;
		}

		span {
			color: $colour-white;
		}
	}

	.ajax-loader {
		background-color: transparent !important;
	}

	.is-invalid {
		.form-control {
			border-color: $colour-red !important;
		}

		.radio-flat-label {
			border: 1px solid $colour-red !important;
		}

		.css-control-indicator {
			border-color: $colour-red !important;
		}

		.select2-container--default {
			.select2-selection--multiple {
				border: 1px solid $colour-red !important;
			}

			.select2-selection--single {
				border: 1px solid $colour-red !important;
			}

			.select2-container--focus {
				.select2-selection--multiple {
					border: 1px solid $colour-red !important;
				}

				.select2-selection--single {
					border: 1px solid $colour-red !important;
				}
			}
		}
	}

	.select2-container--default {
		.select2-results__option--highlighted[aria-selected] {
			background-color: $colour-spearmint;
			color: $colour-indigo;
		}
	}

	.select2-results__option[aria-selected] {
		color: $colour-grey;
	}

	#main-container, #page-footer {
		overflow-x: inherit;
	}

	.content-root {
		overflow-x: inherit;
	}

	.container-fluid {
		overflow: inherit;
	}
}

.swal2-container {
	&.swal2-backdrop-show {
		background: rgba(0,0,0,.4);
	}

	&.swal2-noanimation {
		background: rgba(0,0,0,.4);
	}
}

.swal2-styled.swal2-confirm {
	border-radius: 10px !important;
    padding: 15px 25px !important;
    background: $colour-lime !important;
    background-color: $colour-lime !important;
    border: 2px solid $colour-lime !important;
    color: $colour-indigo !important;
    transition: scale 0.1s;
    font-family: "engrezbold", sans-serif;

	&:focus {
		box-shadow: none !important;
	}
}

.swal2-styled.swal2-cancel {
	border-radius: 10px !important;
    padding: 15px 25px !important;
    background: $colour-red !important;
    background-color: $colour-red !important;
    border: 2px solid $colour-red !important;
    color: $colour-white !important;
    transition: scale 0.1s;
    font-family: "engrezbold", sans-serif;

	&:focus {
		box-shadow: none !important;
	}
}

.swal2-title {
	color: $colour-indigo !important;
	font-size: 1.3em !important;
}

.swal2-popup {
	&.swal2-toast {
		.swal2-title {
			font-size: 1.3em !important;
		}
	}
}

.margin-right-5 {
	margin-right: 5px !important;
}

.modal {
	.ajax-loader {
		background-color: rgba(255, 255, 255, 0.92) !important;
	}
}

.inbound-date-submit-btn {
	margin-top: 26px;
	margin-left: 16px;
}

.text-red {
	color: $colour-red !important;
}

.exefix-banner-redirection {
	background-color: #fff3cd;
	border: 2px solid #ffeeba;
	padding: 20px 30px;
	color: #fff;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;

	p {
		margin: auto;
	}
}

.banner-margin {
	margin-top: 30px;
}

.redirect_btn{
	margin-top:10px;
	color: #fff;
	background-color: #004346;
	padding: 5px 10px;
}

.redirect_btn:hover{
	background-color: #63d481;
}

.top-banner{
	background-color: $colour-red;
	color: $colour-white !important;
	min-height: 40px;
	display: grid;
    place-items: center;

	@media (max-width: 991.98px) {
		padding: 25px 30px;
	}

	@media (max-width: 767.98px) {
		padding: 25px 30px;
	}
}