//
// Header
// --------------------------------------------------

#page-container {
    &.page-header-fixed {
        #page-header {
            position: fixed;
            margin: 0 auto;
            width: 100%;
            background-color: $header-bg;

            .content-header {
                padding: 5px 30px;

                .content-header-section {
                    #page-header-user-dropdown {
                        border-radius: 50% !important;
                        background: #c1eecd;
                        color: #004346;
                        font-family: engrezbold,sans-serif;
                        cursor: pointer;
                        border: none;
                        padding: 9px 0;
                        font-size: 1rem;
                        line-height: 1;
                        width: 34px;
                        height: 34px;

                        @include media-breakpoint-up(xl) {
                            width: 50px;
                            height: 50px;
                            font-size: 24px;
                        }
                    }

                    .content-header-logo {
                        width: inherit;

                        a {
                            position: relative;
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            -webkit-box-pack: center;
                            -ms-flex-pack: center;
                            justify-content: center;
                            align-items: center;
                            text-decoration: none;
                        }

                        img {
                            max-height: 100%;
                            width: 70px;

                            @include media-breakpoint-down(lg) {
                                max-height: 100%;
                                width: 45px;
                            }
                        }

                        span {
                            font-size: 30px;
                            font-family: $font-heading;

                            @include media-breakpoint-down(lg) {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }

            .dropdown-menu {
                &.dropdown-menu-right {
                    top: 0px !important;
                    transform: translate3d(-173px, 40px, 0px) !important;
                    padding-right: 8px !important;
                    padding-left: 8px !important;

                    .h6 {
                        color: $colour-indigo !important;
                    }
                }
            }

            &.animated {
                font-size: 90%;
                height: 60px;

                .dropdown-menu {
                    &.dropdown-menu-right {
                        top: -10px !important;
                        transform: translate3d(-173px, 40px, 0px) !important;
                    }
                }

                .content-header {
                    height: 60px;

                    @include media-breakpoint-up(xl) {
                        .content-header-section {
                            #page-header-user-dropdown {
                                width: 40px;
                                height: 40px;
                                font-size: 20px;
                                -webkit-transition: all .2s;
                                transition: all .2s;
                            }
                        }
                    }

                    .content-header-logo {
                        img {
                            @include media-breakpoint-up(xl) {
                                max-height: 100%;
                                width: 50px;
                            }
                        }

                        span {
                            @include media-breakpoint-up(xl) {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Header overlay (used for various reasons eg: loading or search)
.overlay-header {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $white;
    opacity: 0;
    transition: transform .2s ease-in-out, opacity .2s ease-in-out;
    transform: translateY(-100%);
    will-change: opacity, transform;

    &.show {
        opacity: 1;
        transform: translateY(0);
    }
}

// Inner header sections
.content-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: (($header-height - 80px) / 2) $space-side;
    height: $header-height;

    .content-header-logo {
        position: relative;
        width: inherit;

        img {
            max-height: 100%;
            width: 70px;
        }

        span {
            font-size: 30px;
            font-family: $font-heading;
        }
    }

    &.content-header-fullrow {
        flex-direction: column;
        align-items: stretch;
    }

    &-section {
        flex: 0 0 auto;

        &.content-header-section-wrap {
            flex: 1 1 auto;
        }
    }

    &-item {
        display: inline-block;
        height: $header-height;
        line-height: $header-height;
        vertical-align: middle;

        &-align {
            vertical-align: middle;
        }
    }

    &-logo {
        height: inherit;
        width: 70px;
        flex-shrink: 0;

        img {
            max-height: 100%;
        }
    }

    @at-root #page-header > & {
        padding-left: $space-mobile;
        padding-right: $space-mobile;

        @include media-breakpoint-up(md) {
            padding-left: $space-base;
            padding-right: $space-base;
        }
    }
}

.nav-main-header {
    a {
        text-decoration: none !important;
    }
}

.iam-page.page-header-fixed {
	#page-header {
		background-color: transparent !important;
		box-shadow: none !important;

		.content-header {
			flex-direction: row-reverse;
		}
	}
}
