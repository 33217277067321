.ie {
	display: none;
}

/* IE10+ CSS */
@media all and (-ms-high-contrast:none)
{
	.ie {
		display: inline-block;
	}

	.not-ie {
		display: none;
	}
}

/* IE Edge 12+ CSS */
@supports (-ms-accelerator:true) {
	.ie {
		display: inline-block;
	}

	.not-ie {
		display: none;
	}
}

/* IE Edge 16+ CSS */
@supports (-ms-ime-align:auto) {
	.ie {
		display: inline-block;
	}

	.not-ie {
		display: none;
	}
}