.login-container,.reset-password-container {
	.hero-static {
		-webkit-box-shadow: 0 6px 43px -8px rgba(0,0,0,.35);
		box-shadow: 0 6px 43px -8px rgba(0,0,0,.35);
	}

	&.bg-image {
		background-color: #ffffff;
		background-position: right top;
    	background-size: 45%;
		background-repeat: no-repeat;
	}

	.login-logo,.reset-password-logo {
		img {
			width: 120px;
		}
	}

	.btn-custom {
		padding: 15px 80px;
    	color: $colour-indigo;
		font-family: $font-heading;
		font-size: 18px;
	}

	h1 {
		margin-top: 40px;
		color: $colour-indigo;
		font-size: 40px;
		font-family: $font-heading;
		line-height: 44px;
	}

	h4 {
		color: $colour-lightlime;
		font-size: 16px;
		margin: 10px 0 15px 0;
		text-transform: uppercase;
		padding: 6px 15px;
		display: inline-block;
		background-color: $colour-indigo;
		background-size: 100% 100%;
	}

	.btn-resend {
		margin-top: 12px;
	}

	.p-l-0 {
		padding-left: 0 !important;
	}
	.p-r-0 {
		padding-right: 0 !important;
	}
}

.switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;

	input {
		opacity: 0;
		width: 0;
		height: 0;
	}
}
  
/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;

	&:before {
		position: absolute;
		content: "";
		height: 26px;
		width: 26px;
		left: 4px;
		bottom: 4px;
		background-color: white;
		-webkit-transition: .4s;
		transition: .4s;
	}
}
  
input:checked + .slider {
	background-color: $colour-indigo;
}
  
input:focus + .slider {
	box-shadow: 0 0 1px $colour-indigo;
}
  
input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

.slider.round {
	border-radius: 34px;
}
.slider.round:before {
	border-radius: 50%;
}
