//
// Custom Variables
//
// Here you can add your own variables overrides
//
// The file is included before any other variable file,
// so you can override them because they all use !default
// --------------------------------------------------

// For example, changing the primary color
// $brand-primary: #3b5998;

// Colours
$colour-white: #ffffff;
$colour-grey: #5e5e5e;
$colour-lime: #63d481;
$colour-cream: #f7e8b8;
$colour-lightlime: #a9fad1;
$colour-darklime: #00d56a;
$colour-indigo: #004346;
$colour-spearmint: #c1eecd;
$colour-lightgrey: #e5ecec;
$colour-midgrey: #d9dede;
$colour-midgreen: #35686a;
$colour-red: #a52b16;
$colour-blue: #3f9ce8;
$colour-yellow: #ffe28e;
// Typography
$font-heading: "engrezbold", sans-serif;
$font-subheading: "engrezmedium", sans-serif;
$font-regular: "engrezregular", sans-serif;

// Errors
$colour-error-text: #a94442;
$colour-error-bg: #f2dede;
$colour-error-border: #ebccd1;

// Form Success
$colour-success-text: #155724;
$colour-success-bg: #d4edda;
$colour-success-border: #c3e6cb;

// Form Warning
$colour-warning-text: #b18607;
$colour-warning-bg: #f7eccf;
$colour-warning-border: #d1ad47;

// Timeline
$default-timeline-color: #63d481;
$application-date-color: #63d481;
$return-receipt-date-color: #63d481;
$appointment-date-color: #63d481;
$appointment-date-provided-on-color: #63d481;
$cutover-date-color: #63d481;
$cutover-completed-date-color: #63d481;
$site-visit-install-date-color: #63d481;
$completion-advise-received-date-color: #63d481;
$activation-date-color: #63d481;
$incompleted-stage-color: #a7a1a1;

.font-heading {
    font-family: $font-heading;
}

.font-subheading {
    font-family: $font-subheading;
}

.font-regular {
    font-family: $font-regular;
}

.white {
    color: $colour-white !important;
}

.indigo {
    color: $colour-indigo !important;
}

.lime {
    color: $colour-lime !important;
}

.red {
    color: $colour-red !important;
}

.grey {
    color: $colour-grey !important;
}

.lightgrey {
    color: $colour-lightgrey !important;
}

.bg-indigo {
    background-color: $colour-indigo !important;
}

.bg-lime {
    background-color: $colour-lime !important;
}

.bg-lightgrey {
    background-color: $colour-lightgrey !important;
}

.bg-lightlime {
    background-color: $colour-lightlime !important;
}

.bg-red {
    background-color: $colour-red !important;
}

.bg-yellow {
    background-color: $colour-yellow !important;
}
.bd-red {
    border-color: $colour-red !important;
}

.bd-lime {
    border: 2px solid $colour-lime !important;
}

// Header
$header-height: 80px;

//Material
$font-size-md: 18px;
