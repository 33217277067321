@font-face {
	font-family: 'engrezbold';
	src: url('../fonts/engrez-bold-webfont.woff2') format('woff2'),
	url('../fonts/engrez-bold-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'engrezmedium';
	src: url('../fonts/engrez-medium-webfont.woff2') format('woff2'),
	url('../fonts/engrez-medium-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'engrezregular';
	src: url('../fonts/engrez-webfont.woff2') format('woff2'),
	url('../fonts/engrez-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

body {
	font-family: $font-regular;
	color: $colour-grey;
	font-size: 16px;
}

a {
	color: $colour-lime;
	text-decoration: none;

	&:hover {
		color: $colour-lime;
	}
}

.bold {
	font-family: $font-heading;
}

.sub-bold {
	font-family: $font-subheading;
}

h1, h2, .h1, .h2 {
	color: $colour-indigo;
	font-family: $font-heading;
}

h3, h4, .h3, .h4 {
	color: $colour-indigo;
	font-family: $font-subheading;
}

h5, h6, .h5, .h6 {
	color: $colour-indigo;
	font-family: $font-subheading;
}

.module-inner {
	h1, h2, h3, h4 {
		color: $colour-indigo;
	}
}

h1, .h1 {
	font-size: 24px !important;
}

h2, .h2 {
	font-size: 22px !important;
}

h3, .h3 {
	font-size: 20px !important;
}

h4, .h4 {
	font-size: 18px !important;
}

h5, .h5 {
	font-size: 16px !important;
}

h6, .h6 {
	font-size: 16px !important;
}

.font-small {
	font-size: 14px;
}

.font-smaller {
	font-size: 16px;
}

.font-medium {
	font-size: 18px;
}

.font-large {
	font-size: 20px;
}

.font-larger {
	font-size: 22px;
}

.color-lime {
	color: $colour-lime;
}

.color-spearmint {
	color: $colour-spearmint;
}

.color-indigo {
	color: $colour-indigo;
}

.color-red {
	color: $colour-red;
}

.terms h3 {
	color: $colour-lime;
}
