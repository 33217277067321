.hover-left {
	display: inline-block;
	vertical-align: middle;
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	overflow: hidden;
}

.hover-left:before {
	content: "";
	position: absolute;
	z-index: -1;
	left: -99999px;
	right: 100%;
	bottom: 0;
	background: $colour-lime;
	height: 4px;
	transition-property: right;
	transition-duration: 0.3s;
	transition-timing-function: ease-out;
}

.hover-left:hover:before {
	right: 0;
}

.active-hover-left {
	display: inline-block;
	vertical-align: middle;
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	overflow: hidden;
}

.active-hover-left:before {
	content: "";
	position: absolute;
	z-index: -1;
	left: -99999px;
	right: 100%;
	bottom: 0;
	background: $colour-lime;
	height: 4px;
}

.active-hover-left:before {
	right: 0;
}
