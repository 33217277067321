//
// Main Navigation
// --------------------------------------------------

// Sidebar
.nav-main {
    margin: 0 (-$space-side);
    padding: 0;
    list-style: none;
    background-color: $colour-lightgrey;

    // Default links
    a {
        position: relative;
        display: block;
        padding: 20px 0 20px 70px;
        font-family: $font-heading;
        font-size: 22px;
        color: $colour-midgreen;
        border-top: 1px solid $colour-white;

        > i {
            @include vertical-align(49%, auto, $space-side);
            font-size: 14px;
            color: rgba($brand-dark, .75);
        }

        &:hover,
        &:focus,
        &.active {
            color: $colour-indigo;

            > i {
                color: $brand-primary;
            }
        }

        &.nav-submenu {
            padding-right: 35px;
        }

        &.nav-submenu::before,
        &.nav-submenu::after {
            position: absolute;
            top: 50%;
            right: 15px;
            margin-top: -11px;
            width: 20px;
            height: 20px;
            display: block;
            line-height: 20px;
            text-align: center;
            font-family: $font-family-fontawesome;
            font-size: 40px;
            transition: all $main-nav-transition;
        }

        &.nav-submenu::before {
            content: '\f104';
            opacity: .4;
        }

        &.nav-submenu::after {
            content: '\f103';
            opacity: 0;
            transform: rotate(90deg);
        }

        &.has-children:after {
            content: "";
            display: inline-block;
            background: url('/images/icon-arrow-down.svg') no-repeat 50%;
            background-size: 20px 20px;
            width: 20px;
            height: 20px;
            margin-left: 10px;
        }

        &.external:after {
            content: url('/images/icon-new-window1.png');
            opacity: .4;
        }
    }

    // Sub menus
    ul {
        margin: 0;
        padding: 0 0 0 ($space-side + 22);
        height: 0;
        list-style: none;
        overflow: hidden;

        > li {
            opacity: 0;
            transition: all $main-nav-transition;
            transform: translateY(-15px);
        }

        a {
            padding: 20px 0 20px 70px;
            font-family: $font-heading;
            font-size: 22px;
            color: $colour-midgreen;

            @include hover-focus {
                background-color: transparent;
            }

            > i {
                margin-right: 10px;
            }
        }

        ul {
            padding-left: 12px;
        }
    }

    // Active sub menu
    li.open {
        > a.nav-submenu {
            color: $black;

            > i {
                color: $brand-primary;
            }

            &::before {
                opacity: 0;
                transform: rotate(-90deg);
            }

            &::after {
                opacity: .4;
                transform: rotate(0);
            }
        }

        > ul {
            height: auto;
            padding-top: 5px;
            padding-bottom: 5px;
            background-color: $colour-lightgrey;

            > li {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }
}

#sidebar {
    .bg-mask {
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background: $colour-lightgrey url('../media/bg-mobile-nav.svg') no-repeat left top;
        background-size: 100%;
        width: 100%;
    }
}

.dropdown-menu {
    &.show {
        box-shadow: 0 6px 25px -8px rgba(0, 0, 0, 0.35);
        border-radius: 5px;
        border: none;
    }
}

#page-header-user-dropdown {
    &:hover {
        background: $colour-lime;
        border: 2px solid $colour-lime;
    }

    &:focus {
        background: $colour-lime;
        border: 2px solid $colour-lime;
    }
}

// Header
.nav-main-header {
    display: none;

    @include media-breakpoint-up(lg) {
        margin: 0 -3px;
        padding: 0;
        display: inline-block;
        list-style: none;
        vertical-align: middle;

        li {
            position: relative;
            display: inline-block;
            margin: 0 3px;
        }

        // Default links
        a {
            position: relative;
            display: inline-block;
            padding: 9px 14px;
            font-weight: inherit;
            line-height: 16px;
            border-radius: 3px;
            font-family: $font-subheading;
            color: $colour-indigo;

            > i {
                margin-right: 8px;

                &.si {
                    position: relative;
                    top: 1px;
                }
            }

            &.nav-submenu {
                padding-right: 28px;
            }

            &.nav-submenu::before {
                position: absolute;
                top: 50%;
                right: 6px;
                margin-top: -10px;
                width: 20px;
                height: 20px;
                display: block;
                text-align: center;
                line-height: 20px;
                font-family: $font-family-fontawesome;
            }

            &.nav-submenu::before {
                content: '\f107';
                opacity: .5;
            }

            &.has-children:after {
                content: "";
                display: inline-block;
                background: url('/images/icon-arrow-down.svg') no-repeat 50%;
                background-size: 12px 12px;
                width: 12px;
                height: 12px;
                margin-left: 5px;
            }

            &.external:after {
                content: url('/images/icon-new-window1.png');
                opacity: .4;
            }
        }

        // Sub menu list
        ul {
            position: absolute;
            top: 100%;
            left: 0;
            margin: 0;
            padding: 0;
            display: none;
            -webkit-box-shadow: 0 20px 43px -10px rgba(0,0,0,.35);
            box-shadow: 0 20px 43px -10px rgba(0,0,0,.35);
            border-radius: 0 0 10px 10px;
            background: $colour-white;
            background-size: contain;
            z-index: $zindex-sidebar;

            li {
                display: block;
                margin: 0;
                width: 230px;
                border-bottom: 1px solid $colour-lightgrey;
                padding: 0;
                height: auto;
            }

            a {
                display: block;
                border-radius: 0;
                min-width: 160px;
                white-space: nowrap;
                margin: 0;
                padding: 20px 0 15px 20px;
                width: 100%;
                font-family: $font-subheading;
                color: $colour-indigo;

                &.nav-submenu::before {
                    content: '\f105';
                }
            }

            ul {
                top: -15px;
                left: 100%;
            }
        }

        // Open sub menus on hover
        li:hover {
            > a.nav-submenu {
                @include border-bottom-radius(0);
            }

            > ul {
                display: block;
            }
        }

        li:last-child {
            border-bottom: none;
        }

        // Flip last child’s sub menus
        > li:last-child {
            ul {
                right: 0;
                left: auto;

                a.nav-submenu::before {
                    content: '\f104';
                }

                ul {
                    right: 100%;
                    left: auto;
                }
            }
        }

        a:hover,
        a:focus,
        a.active {
            color: $colour-indigo;
            background-color: inherit;
        }

        ul li a:hover,
        a.nav-submenu:focus,
        a.nav-submenu:hover {
            color: $colour-darklime;
            background-color: inherit;
        }
    }
}
